import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import Introduction from "../components/Global/Introduction"

import { GatsbyImage } from "gatsby-plugin-image";


function ServicesTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout
            header={data.menu}
            services={data.services}
            productCategories={data.productCategories}
            footer={data.footer}
            metaData={constructMetaData(data.productCategory, props.pageContext.currentPage)}
        >
            <div>
                <section>
                    <Introduction backImg={data.productCategory.categoryPageH1.categoryBackgroundImage} title={htmlDecode(data.productCategory.name)} />
                </section>

                <section className="container m-auto px-8 md:px-4 my-16 md:my-24">
                    <div className="flex flex-wrap lg:flex-no-wrap">
                        <div className="w-full lg:w-2/3 xl:w-4/6 pr-0 lg:pr-4">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-12">
                                {data.productCategory?.products && data.productCategory.products.edges.map((product, i) => (
                                    <Link to={`${props.pageContext.currentPage}${product.node.slug}/`}  key={`product-${i}`}>
                                        <GatsbyImage
                                            alt={product.node.featuredImage.node.altText}
                                            loading={"lazy"}
                                            image={product.node.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                            formats={["auto", "webp", "avif"]}
                                            imgClassName={"cat-img"}
                                            style={{border: "1px solid #e1e1e0", borderRadius: "4px"}}
                                            className="cursor-pointer"
                                        />
                                        <Link 
                                            to={`${props.pageContext.currentPage}${product.node.slug}/`} 
                                            className="text-center text-xl w-full block pt-2 product-link"
                                        >
                                            {product.node.title}
                                        </Link>
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 xl:w-2/6 ss-r-tab mt-16 lg:mt-0 pl-0 lg:pl-8">
                            <div>
                                <div className="ss-side-title">
                                    Κατηγορίες
                                </div>
                                <ul className="ss-nav-ul">
                                    {data.productCategories && data.productCategories.edges.map((sc, i) => (
                                        <li key={`nav-sc-${i}`}>
                                            <Link to={`/${sc.node.slug}/`} className={props.pageContext.currentPage === sc.node.slug ? "ss-current-page" : ""}>
                                                {htmlDecode(sc.node.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </Layout>
    )
}

export default ServicesTemplate;

export const pageQuery = graphql`query GET_PRODUCT_CATEGORY($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    productCategory(id: $id) {
        seo {
          canonical
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphImage {
            sourceUrl
          }
        }
        name
        slug
        categoryPageH1{
            categoryImage{
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                    }
                }

            }
            categoryBackgroundImage {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
            }
        }

        products (first: 100){
            edges{
              node{
                title
                content
                slug
                featuredImage{
                    node{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                            gatsbyImageData(quality: 100, layout: CONSTRAINED, width:300, placeholder: BLURRED)
                            }
                        }
                    }
                }
              }
            }
        }
    }

    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
                categoryPageH1{
                    categoryImage{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    }
    footer: template(id: "cG9zdDoyMjE5") {
        id
        title
        footerInformationTemplate {
          footerContent
        address {
          addressGoogleLink
          addressLabel
        }
        hoursLabel
        hoursTitle
        informationTitle
        supportEmail
        telephoneNumber
        servicesTitle
        generalPagesTitle
      }
      }
  }
}
`
